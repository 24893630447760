import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
// import Logo from './LogoMain';
// import LogoIcon from './LogoIcon';
import IOMarket from 'assets/images/icons/logo-icon-io.svg';
import IOMarketLogo from 'assets/images/icons/logo.svg';
import whiteTheme from 'assets/images/icons/download.svg';
import WhiteThemeIOMarketLogo from 'assets/images/icons/whiteThemeLogo.svg';
import { APP_DEFAULT_PATH } from 'config';
import { useTheme } from '@mui/material/styles';
// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}
const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const theme = useTheme();
  return (
    <ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx}>
      {isIcon ? (
        <img src={theme.palette.mode === 'dark' ? whiteTheme : IOMarket} style={{ width: '85%' }} />
      ) : (
        <img src={theme.palette.mode === 'dark' ? WhiteThemeIOMarketLogo : IOMarketLogo} style={{ width: '60%', marginRight: ' 90px' }} />
      )}
    </ButtonBase>
  );
};

export default LogoSection;
