import { lazy, useEffect, useState } from 'react';
import { RouteObject, useNavigate } from 'react-router';
// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { decrypt } from 'utils/commonFunctions';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import { CircularProgress } from '@mui/material';
import useConfig from 'hooks/useConfig';

// render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));

//const Schaan = Loadable(lazy(() => import('pages/schaan')));
//const LQ = Loadable(lazy(() => import('pages/lq')));
const Main = Loadable(lazy(() => import('pages/main')));
const PageNotFound = Loadable(lazy(() => import('pages/notFound')));
//const Salez = Loadable(lazy(() => import('pages/salez')));
//const BW = Loadable(lazy(() => import('pages/bw')));

// const ORB = Loadable(lazy(() => import('pages/orb')));

const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// @ts-ignore
const LegalWrapper = ({ Component }) => {
  const { i18n: language } = useConfig();

  const LazyComponent = Loadable(lazy(() => import(`menu-items/legal/${language}/${Component}_${language}`)));

  return (
    <div style={{ height: 'auto', maxHeight: '83vh', overflowY: 'auto' }}>
      <LazyComponent />
    </div>
  );
};

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = () => {
  const iomURL = process.env.REACT_APP_IOM_URL;
  const [branches, setBranches] = useState<{ name: string; code: string }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useAuth().isLoggedIn;
  const navigate = useNavigate();

  const getUserNavbranches = async () => {
    try {
      const userData: any = localStorage.getItem('userData');
      const navRights: any = localStorage.getItem('userRights');
      let data = null;
      if (userData && navRights) {
        var obj = JSON.parse(decrypt(userData));
        let formData = new FormData();
        formData.append('rights', navRights);
        formData.append('companyUserId', obj.COMPANYUSERID);
        formData.append('userId', obj.USERID);
        formData.append('loggedInTime', obj.LOGGEDINTIME);
        const response = await axios.post(`${iomURL}/getBranches`, formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        data = response.data;
        setBranches(response.data.data);
      }
      if (data?.STATUS === true) {
        // localStorage.setItem('getRights',(data?.DATA));
      }
    } catch (error) {
      console.error('Error fetching branches', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (isLoggedIn) {
        await getUserNavbranches();
        setIsLoading(false); // Set loading to false after all data is fetched
      } else {
        navigate('/login');
        setIsLoading(false); // Set loading to false if not logged in
      }
    };

    fetchUserDetails();
  }, [isLoggedIn]);
  const appsRoutes = branches.map((branch) => ({
    path: branch.code.toLowerCase(),
    //path: branch.name.split('/')[0].toLowerCase().replace(/\s+/g, ''),
    element: (
      <AuthGuard>
        <Main cityCode={branch.code.toUpperCase()} />
      </AuthGuard>
    ),
    rights: `ngtMLie${branch.code.toUpperCase()}`
  }));
  const routes: RouteObject[] = [
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            // {
            //   path: 'dashboard',
            //   children: [
            //     {
            //       path: 'default',
            //       element: <DashboardDefault />
            //     }
            //   ]
            // }, {
            {
              path: '/terms-condition',
              element: <LegalWrapper Component={'terms_and_conditions'} />
            },
            {
              path: '/privacy-policy',
              element: <LegalWrapper Component={'privacy_policy'} />
            },
            {
              path: '/legal-notices',
              element: <LegalWrapper Component={'legal_notices'} />
            },

            {
              path: 'apps',
              children: [
                ...appsRoutes,

                {
                  path: 'profiles',
                  children: [
                    {
                      path: 'account',
                      element: <AccountProfile />,
                      children: [
                        {
                          path: 'basic',
                          element: <AccountTabProfile />
                        },
                        {
                          path: 'personal',
                          element: <AccountTabPersonal />
                        },
                        {
                          path: 'my-account',
                          element: <AccountTabAccount />
                        },
                        {
                          path: 'password',
                          element: <AccountTabPassword />
                        },
                        {
                          path: 'role',
                          element: <AccountTabRole />
                        },
                        {
                          path: 'settings',
                          element: <AccountTabSettings />
                        }
                      ]
                    },
                    {
                      path: 'user',
                      element: <UserProfile />,
                      children: [
                        {
                          path: 'personal',
                          element: <UserTabPersonal />
                        },
                        {
                          path: 'payment',
                          element: <UserTabPayment />
                        },
                        {
                          path: 'password',
                          element: <UserTabPassword />
                        },
                        {
                          path: 'settings',
                          element: <UserTabSettings />
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: '/maintenance',
          element: <CommonLayout />,
          children: [
            {
              path: '404',
              element: <MaintenanceError />
            },
            {
              path: '500',
              element: <MaintenanceError500 />
            },
            {
              path: 'under-construction',
              element: <MaintenanceUnderConstruction />
            },
            {
              path: 'coming-soon',
              element: <MaintenanceComingSoon />
            }
          ]
        },
        {
          path: '/auth',
          element: <CommonLayout />,
          children: [
            {
              path: 'login',
              element: <AuthLogin />
            },
            {
              path: 'register',
              element: <AuthRegister />
            },
            {
              path: 'forgot-password',
              element: <AuthForgotPassword />
            },
            {
              path: 'reset-password',
              element: <AuthResetPassword />
            },

            {
              path: 'code-verification',
              element: <AuthCodeVerification />
            }
          ]
        },
        {
          path: '*',
          element: <PageNotFound />
        }
      ]
    }
  ];
  if (isLoading) {
    return [
      {
        path: '*',
        element: <CircularProgress color="inherit" sx={{ width: '40px', height: '40px', position: 'absolute', top: '50%', left: '50%' }} />
      }
    ];
  } else {
    return routes;
  }
};

export default MainRoutes;
