import { useTheme } from '@mui/material/styles';
import { Box, Tooltip } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { ThemeMode } from 'types/config';
import useConfig from 'hooks/useConfig';
import { useIntl } from 'react-intl';

// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

const Customization = () => {
  const theme = useTheme();
  const { mode, onChangeMode } = useConfig();

  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  const handleModeChange = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';

    onChangeMode(newMode as ThemeMode);
  };

  const intl = useIntl();

  return (
    <>
      <Tooltip title={intl.formatMessage({ id: `change_theme` })}>
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
          <IconButton
            color="secondary"
            variant="light"
            sx={{
              color: 'text.primary',
              bgcolor: iconBackColor
            }}
            onClick={handleModeChange}
            aria-label="Theme toggler"
          >
            {theme.palette.mode === ThemeMode.DARK ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
        </Box>
      </Tooltip>
    </>
  );
};

export default Customization;
